import React from 'react';
import PropTypes from 'prop-types';
import LayoutWithSider from 'components/layout/withsider';
import { graphql } from 'gatsby';
import AppContext from 'store/provider';
import HoraTable from 'components/horatable';

const Hora = () => {
    return (
      <AppContext.Consumer> 
      {({ horses, sane_horse_data, updateStatus, toggleSym }) => {
        
        const hora_data = sane_horse_data.filter(node => node.hip >= 3598);
        return (
          <LayoutWithSider>
              <HoraTable horses={horses} sane_horse_data={hora_data} status={updateStatus} toggleSym={toggleSym} />
          </LayoutWithSider> 
          )}}
      </AppContext.Consumer>
  )};
  

  
  export default Hora;