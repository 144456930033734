import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Sidebar from 'components/sidebar';
import SearchInput from 'components/sidebar/searchBar';
import NavBottom from 'components/header/nav/navBottom';
import Nav from 'components/header/nav/nav';
import { useMediaQuery } from 'react-responsive';
import AppContext from 'store/provider';
import '../global.css'

import Layout from 'antd/es/layout';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import  bgHeaderImage from 'assets/images/Header.jpg';
import { Banner } from '../banner';

const Header = Layout;
const Sider = Layout; 
const Footer = Layout;
const Content = Layout;

const LayoutWithSider = ({ data, children }) => {

const { horses, showMenu, showModal, menu, modal } = useContext(AppContext);
const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
const isBigScreen = useMediaQuery({ query: '(min-width: 769px)' });
const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return  <Layout >
                <Row>
                    <Col flex="auto" >
                            <Sidebar customBurgerIcon={ false } pageWrapId={"sroot"} outerContainerId={"App"} isOpen={menu} onClose={showMenu} >
                            <SearchInput
                                id="grp"
                                label="HipNo, Sire, Dam, Sex, Consignor"
                                horses={horses}
                                predicted="Gluestick"
                                locked={false}
                                active={false}
                                className="form-control py-2 border-right-0 border" />
                            </Sidebar>
             
                        <Layout className="site-layout" >
                            <Header style={{height: (!isTabletOrMobile) ? '90px' : ((isMobile) ?  '60px' : '50px')}}>
                                <Banner bgImage={bgHeaderImage}  />
                            </Header>
                            <Content >
                              <div id="page-wrap" >
                                  {children}
                              </div>
                            </Content>
                            <Footer ><NavBottom OpenMenu={showMenu} OpenModal={showModal} open={menu} modal={modal} ></NavBottom></Footer>
                        </Layout>
                    </Col>
                </Row>
            </Layout> 
};

LayoutWithSider.propTypes = {
  children: PropTypes.node.isRequired,
  // data: PropTypes.object.isRequired,
};

export default LayoutWithSider;
