import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Table from 'antd/es/table';
import Spin from 'antd/es/spin';
import { pick, isNil, isEmpty, all } from 'ramda';
import { EditableCell, EditableRow } from './editablecell';
import { init$filters } from 'components/antdtable/constants';
import { getDownload } from 'components/search-sessions/helpers';
import { useMediaQuery } from 'react-responsive';

import { filterSearch } from '../../utils';

import PageHeader from 'antd/es/page-header';
import Button from 'antd/es/button';
import { LoadingOutlined } from '@ant-design/icons';
import { MobileMenu } from './mobile-menu';
import background from '../../assets/background.png';

export default function HoraTable({ horses, sane_horse_data, status, toggleSym }) {
    // check if we have horse nodes
    if(isNil(horses) || isEmpty(sane_horse_data)) return <div className="example" ><Spin /></div>

    const [fields, setFields] = useState({init: init$filters});
    const [focused, setFocus] = useState(null);

    const isMobile = useMediaQuery({ maxDeviceWidth: 575 });
    const isNotTall = useMediaQuery({ maxHeight: 450  });
    const isLandscape = useMediaQuery({ orientation: 'landscape', maxDeviceHeight: 450 });

    const [horse_data, setData] = useState(sane_horse_data);

    const [downloading, setDownload] = useState(false);
    const [error, setError] = useState(null);

    const formState = typeof window !== `undefined` && window.history.state && window.history.state.formState || null;

    useEffect(() => {
        const el = document.getElementById('bottomNav') || null;
        
        if(el)
          el.style.display = 'none';
    
        // handle the previous search, we pass the form state search criteria via state prop in gatsby-link.
        if(formState){
          
          if(!all(isNil)(Object.values(formState.init))) { 
            const prevView = filterSearch(formState.init, sane_horse_data); 
            setData(!prevView ? sane_horse_data : prevView);
          } else {
            setData(sane_horse_data);
          }
          
          setFields({init: formState.init});
          
        }
          
        return () => {
          const el = document.getElementById('bottomNav') || null;
          if(el)
            el.style.display='block';
          
        }
      }, []);

    const columns = [
        { 
          title: 'Hip', 
          dataIndex: 'hip', 
          key: 'hip', 
          render: (hip_num, record) => <Link className="hip-link" 
          to={`/catalog/hip/${hip_num}/?103`} 
          style={{display: 'flex', alignItems: 'flex-end'}}
          // this is our form state passed to the template
            state={{formState: {
                init: fields.init, 
                session: 10,
                hora: true
            }}} 

          ><span className="hip-text" >{record.hip_id}</span><div className="symStack" >{record.symbols}</div></Link>,
          sorter: (a, b) => a.hip.localeCompare(b.hip),
          sortDirections: ['ascend', 'descend'],
            fixed: 'left',
          width: '7%',
          responsive: ['sm'],
          onHeaderCell: () => ({title: 'hip'})
        },
        { 
          title: 'Sex', 
          dataIndex: 'sex', 
          key: 'sex',
          width: '4%',
          responsive: ['sm'],
          sorter: (a, b) => a.sex.localeCompare(b.sex),
          onHeaderCell: () => ({title: 'sex'})
        },
        // november sale
        { 
          title: 'Sold As', 
          dataIndex: 'sold_as', 
          key: 'sold_as',
          width: '10%',
          responsive: ['sm'],
          sorter: (a, b) => a.sold_as.localeCompare(b.sold_as),
          onHeaderCell: () => ({title: 'sold_as'})
        },
        /***************************/
        { 
          title: 'Horse', 
          width: '9%',
          dataIndex: 'horse', 
          key: 'horse',
          responsive: ['sm'],
          sorter: (a, b) => a.horse.localeCompare(b.horse),
          onHeaderCell: () => ({title: 'horse'})
        },
        { 
          title: 'Sire', 
          dataIndex: 'sire', 
          key: 'sire',
          width: '9%',
          responsive: ['sm'],
          sorter: (a, b) => a.sire.localeCompare(b.sire),
          onHeaderCell: () => ({title: 'sire'})
        },
        { 
          title: 'Dam', 
          dataIndex: 'dam', 
          key: 'dam',
            width: '9%',
          responsive: ['sm'],
          sorter: (a, b) => a.dam.localeCompare(b.dam),
          onHeaderCell: () => ({title: 'dam'})
        },
        // november sale
        { 
          title: 'Covering Sire', 
          dataIndex: 'covering_sire', 
          key: 'covering_sire',
          responsive: ['sm'],
          sorter: (a, b) => a.covering_sire.localeCompare(b.covering_sire),
          onHeaderCell: () => ({title: 'covering_sire'})
        },
        { 
          title: 'Preg', 
          dataIndex: 'preg', 
          key: 'preg',
          width: '75px',
          responsive: ['sm'],
          sorter: (a, b) => a.preg.localeCompare(b.preg),
          onHeaderCell: () => ({title: 'preg'})
        },
        /************************* */
        { 
          title: 'Consignor',
          //width: '50%',
            width: '30%',
          render: (record) => (
            (!isNil(record.website)) ? 
            <a className="hip-link" key={`consignor-web-${Math.random()}`} href={`${record.website}`} style={{display: 'flex', alignItems: 'flex-end'}} target="_blank" rel="noopener noreferrer"><span className="hip-text" >{record.name}</span></a> : `${record.name}`),
          dataIndex: 'consignor', 
          key: 'consignor',
          responsive: ['sm'],
          sorter: (a, b) => a.consignor.name.localeCompare(b.consignor.name),
          onHeaderCell: () => ({title: 'consignor'})
        },
        { 
          title: 'Out', 
          dataIndex: 'out', 
          key: 'out',
          width: '5%',
          align: 'center',
          responsive: ['sm'],
          sorter: (a, b) => a.out.localeCompare(b.out),
          render: (status) => (<span style={{color: 'red'}}>{(status === 'Y') ? 'OUT' : ''}</span>),
          onHeaderCell: () => ({title: 'out'})
        },
        // xs columns - responsive
        { 
          title: 'Hip', 
          dataIndex: 'hip', 
          key: 'hip', 
          render: (hip_num, record) => <Link className="hip-link" to={`/catalog/hip/${hip_num}/?103`} title={'hip'} 
          state={{formState: {
                init: fields.init, 
                session: 10,
                hora: true
            }}} 
          >
    
            <span className="hip-text">{record.hip_id}</span>{(record.out === 'Y') && <><br/><span style={{color: 'red', borderTop: '1px solid #135740'}}>{'OUT'}</span><br/></>}<div className="symStack" style={{position: 'relative', bottom: '-6px'}}>{record.symbols}</div></Link>,
          sorter: (a, b) => a.hip - b.hip,
          sortDirections: ['ascend', 'descend', 'ascend'],
          
            fixed: 'left',
          responsive: ['xs'],
          width: '75px',
          
          onHeaderCell: () => ({title: 'hip'})
        },
        { 
          title: 'Horse', 
          dataIndex: 'horse_sirdam', 
          key: 'horse',
          responsive: ['xs'],
          sorter: (a, b) => a.horse.localeCompare(b.horse),
          render: (value, record) => (<div><span>{value}</span><br/><span>{record.sex}</span></div>),
          width: '100px',
          onHeaderCell: () => ({title: 'horse'})
        },
        { 
          title: 'Consignor/Sold As', 
          dataIndex: 'consignor', 
          key: 'consignor',
          responsive: ['xs'],
          sorter: (a, b) => a.consignor.name.localeCompare(b.consignor.name),
          render: (value, record) => (<div>{!isNil(value.website) ? 
          <a className="hip-link" key={`consignor-web-${Math.random()}`} href={`${value.website}`} style={{display: 'flex', alignItems: 'flex-end'}} target="_blank" rel="noopener noreferrer"><span className="hip-text" >{value.name}</span></a> : <span style={{fontWeight: 'bold'}}>{value.name}<br/></span>}<span>{record.sold_as}</span></div>),
          onHeaderCell: () => ({title: 'consignor'})
        },
      ];

      var scrolly = null;

      if(isNotTall || isMobile) {
        if(isNotTall) {
        }
        else  scrolly = { y: 'calc(100vh - 370px)' };
    } else {
      scrolly =  { y: 'calc(100vh - 340px)', x: 1151 };
    }

   /**
   * Form control
   * 
   * @param {Object} formData - contains user input data for horse node search
   * @param {*} foc - current focused input referrence.
   */
    const findItem = (formData=null, foc=null) => {
        // get the current form field values.
        const dataInput = formData.getFieldValue();

        // filter by form fields.
        const filteredData = filterSearch(dataInput, sane_horse_data);
        
        setData(filteredData);
        
        // set the current input focus, managed manually because the inputs are controlled.
        setFocus(foc);
        
        // log the current search inputs.
        formData.submit();
    };

    /**
     * Form Control
     * 
     * @param {Object} fields - form submitted inputs.
     * @returns Object of data for all form inputs.
     */
    const onFormFinish = (newfields) => (setFields({...fields, init: newfields}));

      return (
        <PageHeader 
        title={'2021 HORA Index - Session 10'}
        style={{
            background: `url(${background})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            fontFamily: '"Open sans", sans-serif',
            height: (isLandscape) ? '100%' : null , 
        }}
        className="respond-landscape"
        ghost={true}
        extra={(isMobile) ? [<MobileMenu key={`mobile-menu-1`} setError={setError} setDownload={setDownload} error={error} formData={fields.init} />] : [
            <Button size="large" key="2" onClick={async () => {
                try {
                    await getDownload(fields.init, 'pdf', 10, setDownload); 
                } catch(e) {
                    setError(e);  
                }
                
            }} >{(downloading && downloading === 'pdf') ? <Spin id="spinner-pdf" key="spinner-1" indicator={<LoadingOutlined size='large' style={{ color: 'black' }}/>} /> : 'PDF'}</Button>,
            <Button size="large" key="3" onClick={async () => {
                try {
                    await getDownload(fields.init, 'csv', 10, setDownload)
                } catch(e) {
                    setError(e)
                }
                
            }} >{(downloading && downloading === 'csv') ? <Spin id="spinner-csv" key="spinner-2" indicator={<LoadingOutlined size='large' style={{ color: 'black' }}/>} /> : 'CSV'}</Button>,
            <Button size="large" key="4" ><a href="http://flex.keeneland.com/horsesearch/horsesearch.html?catalog=Y" target="_blank"  rel='noreferrer noopener'>SEARCH</a></Button>,
            <Button size="large" key="1" ><a href="/catalog">MAIN CATALOG</a></Button>,
            <Button size="large" key="5" ><a href="https://www.keeneland.com/sales/download-books-and-indexes" target="_blank"  rel='noreferrer noopener'>CATALOG DOWNLOADS</a></Button>,
            <Button size="large" key="6" ><a href="http://flex.keeneland.com/updates/updates.html" target="_blank"  rel='noreferrer noopener'>CATALOG UPDATES</a></Button>,    
        ]}
    >
        <Table
            className="components-table"
            columns={columns}
            pagination={{pageSize: 500}}
            tableLayout={(!isMobile) ? 'unset' : 'fixed'}
            style={{background: '#eeeeee'}}
            dataSource={horse_data}
            bordered
            loading="true"
            scroll= { scrolly	}
            components={
                { 
                  header: {
                    row: (props) => (<EditableRow {...props} onFormFinish={onFormFinish} fields={fields.init}  />),
                    cell: (props) => {
                      var column$index =  props.title;
                      
                      var sorterProps = pick(['onClick', 'children'], props);
                      var rest = pick(['title', 'className', 'colSpan', 'rowSpan', 'style'], props);
        
                      // scrollbar
                      if (column$index === undefined)
                        return <th {...props} sticky={'true'} />;
                      
                      return <th {...rest}  ><div {...sorterProps} >{props.children}</div><EditableCell search_list={findItem} name={column$index} fields={fields.init} key={column$index} hasFocus={focused} /></th>},
                  
                }
              }
             }
        />
        </PageHeader>
      )
}