import React, { useContext, useState, useRef, useEffect } from 'react';
import Form from 'antd/es/form';
import Input from 'antd/es/input'; 

// editable input context data store.
const EditableContext = React.createContext(null);
const { getFieldDecorator } = Form
/**
 * This is used as a custom component to replace the default table header and include
 * the form inputs.
 * 
 * @param {*} properties - props passed from parent component 
 * @returns React Component Form.
 */
export const EditableRow = ({ name, onFormFinish, fields, ...props }) => {
    const [form] = Form.useForm();
    
    return (
      <Form form={form} component={false} onFinish={(values) => {
            onFormFinish(values);
        }} 
        fields={[fields]}
        >
        <EditableContext.Provider value={form}>
          <tr {...props} />
        </EditableContext.Provider>
      </Form>
    );
};

/**
 * This is the input elements for the table header. A custom component
 * to replace the default table cell.
 * 
 * @param {*} props - passed from parent component or context. 
 * @returns React Component Form Item Input.
 */
export const EditableCell = (props) => {
    const { title, search_list, name, fields, hasFocus } = props;
    const form = useContext(EditableContext);
    
    const inputRef = useRef(name);
    const dataIndex = name;

    useEffect(() => {
        // controlled component has to update focus
        if(inputRef.current.input.id === hasFocus) {
            inputRef.current.focus();
        }
        
        return;
    }, []);

    return  <Form.Item  name={dataIndex} initialValue={fields[`${dataIndex}`]} >
                <Input ref={inputRef} bordered placeholder={title} onChange={(e) => {
                    e.preventDefault();
                    search_list(form,name);
                }} />
            </Form.Item>      
}