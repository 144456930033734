import React, { useState, useEffect, useContext } from 'react';
import Button from 'antd/es/button';
import Menu from 'antd/es/menu';
import Select from 'antd/es/select';
import Drawer from 'antd/es/drawer';
import { ConsoleSqlOutlined, MenuOutlined, SyncOutlined } from '@ant-design/icons';
import { getDownload } from 'components/search-sessions/helpers';
import Spin from 'antd/es/spin';
import { LoadingOutlined } from '@ant-design/icons';
import Alert from 'antd/es/alert';
import { PlaceholderSVG } from 'components/search-sessions/helpers';


const { Option } = Select;

export const MobileMenu = (props) => {
    
    const [show, setDrawer] = useState(false);
    const [selected, setSelected] = useState(1);
    const [downloading, setDownload] = useState(false);

    const showDrawerMenu = () => (setDrawer(true));
    const closeDrawerMenu = () => (setDrawer(false));

    const { filterDataSource, setError, formData, error } = props

    useEffect(() => {
        
        return () => {

        }
    }, []);

    const handleChange = (value) => {
        setSelected(value);
        closeDrawerMenu();

        filterDataSource(value);
    };

    return (
        <>
        <Button onClick={showDrawerMenu}><MenuOutlined /></Button>
        
        <Drawer
            title="Session Search"
            placement={'left'}
            closable={false}
            onClose={closeDrawerMenu}
            visible={show}
            key={'left'}
            
            >
            
            <Menu>
                <Menu.Item key={`menu-item-3`}>
                    <Button block="true" key="2" onClick={async () => {
                        try {
                            await getDownload(formData, 'pdf', 10, setDownload); 
                        } catch(e) {
                            setError(e);  
                        }
                    
                    }} >{(downloading && downloading === 'pdf') ? <Spin id="spinner-pdf" key={`spinner-1`} indicator={<LoadingOutlined size='large' style={{ color: 'black' }}/>} /> : 'PDF'}</Button>
                </Menu.Item>
                <Menu.Item key={`menu-item-4`}>
                    <Button block="true" key="3" type="primary" onClick={async () => {
                        try {
                            await getDownload(formData, 'csv', 10, setDownload)
                        } catch(e) {
                            setError(e)
                        }
                        
                    }}>{(downloading && downloading === 'csv') ? <Spin id="spinner-csv" key={`spinner-2`} indicator={<LoadingOutlined size='large' style={{ color: 'black' }}/>} /> : 'CSV'}</Button>
                </Menu.Item>
                <Menu.Item key={`menu-item-5`}>
                    <Button block="true" key="4" ><a href="http://flex.keeneland.com/horsesearch/horsesearch.html?catalog=Y" target="_blank"  rel='noreferrer noopener'>SEARCH</a></Button>
                </Menu.Item>
                <Menu.Item>
                    <Button  block="true" key="1" ><a href="/catalog">MAIN CATALOG</a></Button>
                </Menu.Item>
                <Menu.Item key={`menu-item-6`}>
                    <Button block="true" key="5" style={{padding: 0}} ><a href="https://www.keeneland.com/sales/download-books-and-indexes" target="_blank"  rel='noreferrer noopener'>CATALOG DOWNLOADS</a></Button>
                </Menu.Item>
                <Menu.Item key={`menu-item-7`}>
                    <Button block="true" key="6"><a href="http://flex.keeneland.com/updates/updates.html" target="_blank"  rel='noreferrer noopener'>CATALOG UPDATES</a></Button>
                </Menu.Item>
            </Menu>
            {(error) && <Alert type="error" message={`${error}`} banner closable onClose={() => {
                setDownload(false);
                setError(null);
            }}/>}
            <div dangerouslySetInnerHTML={{__html: PlaceholderSVG}}  /> 
        </Drawer>
        </>
    )
}
