import React from 'react';
import './banner-styles.css';
import { useMediaQuery } from 'react-responsive';
import menu_white from '../../assets/menu-white.png';
import ke_logo from '../../assets/keeneland-logo.png';
import calendar_white from '../../assets/icons/calendar-white.png';

export const Banner = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 769px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return <div className="overlay-wrapper" style={{height: (!isTabletOrMobile) ? '90px' : ((isMobile) ?  '60px' : '50px') , position: 'fixed', width:'100%', zIndex: 1000}}>
      <div className="embed-responsive embed-responsive-3by1 bg-cover bg-center hidden-md hidden-lg" style={{
        height: "100%", 
        backgroundImage: `url(${props.bgImage})`,
        display:'grid',
        gridTemplateRows:'1fr',
        gridTemplateColumns: '15px minmax(0, 90vw) 15px',
        justifyContent: 'center',
        alignContent: 'center' }} >
        <div style={{
          display: 'flex',
          gridColumn: 2,
          alignSelf: 'center',
          textAlign: 'center',
          zIndex:1
        }}>
          <button type="button" className="navbar-toggle" data-toggle="modal" data-target="#nav-modal" id="primary-nav-toggle" style={{display: 'none', padding: '0', border: 'none', background: 'none' }}>
            <span className="sr-only">Toggle navigation</span>
            <span className="margin-lft-xl padding-lft-md hidden-sm hidden-xs pull-left display-block"></span>
            <img src={menu_white} style={{ width: "100%", maxWidth: "100%" }} alt="Menu"/>
          </button>
          <a className="logo navbar-btn pull-left no-margin no-padding full-width" href="/" title="Home">
            <img src={ke_logo} alt="Home" className="img-responsive img-center hidden-lg hidden-md margin-top-15 margin-btm-15" style={{maxHeight: "40px"}}/>
          </a>
          <button type="button" className="navbar-toggle pull-right calendar-nav-toggle" id="calendar-nav-open" style={{display:'none'}}>
            <span className="sr-only">Toggle calendar</span>
            <span className="margin-rgt-xl padding-rgt-md hidden-sm hidden-xs pull-right display-block"></span>
            <img src={calendar_white} alt="Calendar"/>
          </button>
        </div>
        
        <div className="overlay overlay-cover bg-gradient" style={{
          height: '100%',
          width: '100%',
          overflow: 'auto',
          top: '0px',
          left: '0px',
          background: 'rgba(0, 0, 0, 0.5)'
        }}></div>
        <div className="overlay overlay-btm-lft full-width">
          <div className="container-fluid">
          
          </div>
        </div>
      </div>
    </div>
}
